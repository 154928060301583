import { Button, Title } from '@components/Common';
import ContactFormNewDesign from '@components/shared/ContactFormNewDesign';
import { useLocale } from '@components/Contexts/LocaleContext';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { FC } from 'react';

interface Props {
  product: string;
  event: string;
  scroll?: any;
  customContainer?: string;
}

const FormContact: FC<Props> = ({ product, event, scroll, customContainer = '' }) => {
  const { lang } = useLocale();
  const router = useRouter();
  const contactRef = React.useRef() as any;

  const title = {
    en: <>Let us design your Digital Journey!</>,
    th: (
      <>
        ให้เราช่วยออกแบบ Solutions
        <br />
        ที่ตอบโจทย์คุณ
      </>
    ),
  };

  const description = {
    en: (
      <>
        We&apos;re the leading digital native solution provider, standing by
        your side—helping you harness digital solutions the right way.
      </>
    ),
    th: (
      <>
        เราเป็นผู้ให้บริการโซลูชันดิจิทัลเนทีฟชั้นนำ{' '}
        <br className="md:hidden" />
        ที่พร้อมอยู่เคียงข้างคุณ—
        <br className="hidden xxl:block" />
        ช่วยให้คุณใช้งานโซลูชันดิจิทัลได้อย่างถูกต้องและมีประสิทธิภาพ
      </>
    ),
  };

  useEffect(() => {
    if (scroll) {
      window.scrollTo({
        behavior: 'smooth',
        top: contactRef.current.offsetTop - 48,
      });
    }
  }, [scroll]);

  return (
    <section
      ref={contactRef}
      aria-labelledby="homepage-form-contact"
      className="relative w-full py-12 bg-black"
    >
      <div className={`flex-column lg:flex-row gap-9 ${customContainer ? customContainer : 'Container'}`}>
        <div className="flex-1">
          <div className="flex flex-col gap-5">
            <Title className="md:text-left px-2">{title[lang]}</Title>
            <p className="!text-p2Regular !lg:text-p1Regular xxl:!text-[16px] xxl:!leading-[24px] text-center md:text-left px-2">
              {description[lang]}
            </p>
            <div className="fit-content mx-auto md:mx-0 px-2">
              <Button
                id="contact-us-esignature"
                icon="/icons/speech.svg"
                text={lang === 'en' ? 'CONTACT US' : 'ติดต่อเรา'}
                className="text-sm border border-white"
                onClick={() => router.push('/contact-us')}
              />
            </div>
          </div>
        </div>
        <div className="flex-1 lg:pl-10">
          <ContactFormNewDesign
            product={product}
            event={event}
          />
        </div>
      </div>
    </section>
  );
};

export default FormContact;
