import axios from 'axios';
import { strapi } from '../models/conent';
import { captureException } from '@sentry/react';

// export const strapiForm = `${strapi}/admin/plugins/content-manager/collectionType/application::codium-form.codium-form`;
export const strapiForm = `${strapi}/admin/content-manager/collectionType/api::codium-form.codium-form`;

export const SendMail = (form: mailForm, toSale = true) => {
  const saleForm = {
    from: 'admin@codium.co',
    subject: form?.subject ? form.subject : 'Lead In Alert',
  };

  const officeForm = {
    from: 'admin@codium.co',
    subject: 'Candidate Alert',
  };
  axios.post(`${strapi}/api/email`, toSale ? { ...saleForm, ...form } : { ...officeForm, ...form }).catch((error) => {
    captureException(error);
  });
};

interface mailForm {
  to: string;
  html: string;
  subject?: string;
}
