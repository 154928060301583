export const translate = (item, name: 'description' | 'title' | 'label' | 'name' | 'placeHolder' | 'detail', lang) => {
  if (!item) return;
  const translated = lang === 'en' ? item[name] : item[`${name}TH`];
  return translated ? translated : item[name];
};

export const titleOnly = (item) => {
  if (!item) return;
  const titleItem = JSON.parse(JSON.stringify(item));
  delete titleItem.subContent;
  return titleItem;
};

export const findContent = (data, name: string) => {
  return data?.find((item) => item.name === name);
};

export const findContentByTitle = (data, name: string) => {
  return data?.find((item) => item.title === name);
};

export const filterContent = (data, name: string) => {
  return data?.filter((item) => item.name === name);
};

export const translate2 = (th: any, en: any, lang: 'en' | 'th') => {
  return lang === 'en' ? en : th;
}
