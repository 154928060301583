import { FC } from 'react';

interface HoneyPotProps {
  register: any;
  onChange?: any;
}

const HoneyPot: FC<HoneyPotProps> = ({ register, onChange }) => {
  return (
    <div
      className="absolute -left-[500px]"
      aria-hidden="true"
      style={{ zIndex: -1 }}
    >
      <label htmlFor="user_code"></label>
      <input
        type="text"
        id="user_code"
        name="user_code"
        autoComplete="off"
        placeholder="User Code"
        {...register('user_code', {
          onChange: (e) => onChange(e),
        })}
      />
    </div>
  );
};

export default HoneyPot;
