import Link from 'next/link';
import {
  ConsentPurposeDetail,
  ConsentPurposeType,
  PDPAInfoPayload,
} from './PDPAConsent.model';
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { useLocale } from '@components/Contexts/LocaleContext';
import { translate2, translate } from 'utils/translate';

interface Props {
  choices: ConsentPurposeType[];
  payload: PDPAInfoPayload;
  setPayload: Dispatch<SetStateAction<PDPAInfoPayload>>;
  valid: boolean;
  setValid: Dispatch<SetStateAction<boolean>>;
  mode?: 'light' | 'dark';
}

const PDPAConsent: FC<Props> = ({
  choices,
  payload,
  setPayload,
  valid,
  setValid,
  mode = 'light',
}) => {
  const [uiCheckbox, setUICheckbox] = useState<{
    [k in ConsentPurposeType]: boolean;
  }>({
    'PP-1': false,
    'PP-2': false,
    'PP-3': false,
    'PP-4': false,
    'PP-5': false,
  });
  const [showSendBy, setShowSendBy] = useState<boolean>(true);
  const { lang } = useLocale();

  useEffect(() => {
    // update uiCheck when payload updated
    const purposes = payload?.purposes;
    if (!purposes) return;

    let updated = {};
    purposes.forEach((p) => {
      updated[p.purpose] = p.consented;
    });
    setUICheckbox({ ...uiCheckbox, ...updated });

    // validate form
    const allFalse = purposes.every((p) => !p.consented);
    if (allFalse) {
      setValid(true);
      return;
    }

    const selectSomeSendBy = purposes
      .filter((p) => ['PP-4', 'PP-5'].includes(p.purpose))
      .some((p) => p.consented);
    setValid(selectSomeSendBy);
  }, [payload]);

  const toggleCheckbox = (checkedType: ConsentPurposeType) => {
    let { purposes } = payload;
    purposes = purposes.map((p) => {
      if (p.purpose !== checkedType) return p;

      p.consented = !p.consented;
      return p;
    });
    setPayload({ ...payload, purposes });
  };

  const handleSelectChoice = (checkedType: ConsentPurposeType) => {
    toggleCheckbox(checkedType);

    const isCheckedSomeChoices = (payload?.purposes ?? [])
      .filter((p) => choices.includes(p.purpose))
      .some((p) => p.consented);
    if (isCheckedSomeChoices) {
      autoSelectSendByEmail();
      return;
    }

    const noCheckedAnyChoices = (payload?.purposes ?? [])
      .filter((p) => choices.includes(p.purpose))
      .every((p) => !p.consented);
    if (noCheckedAnyChoices) {
      clearAllCheck();
    }
  };

  const handleSelectSendBy = (checkedType: ConsentPurposeType) => {
    toggleCheckbox(checkedType);
  };

  const autoSelectSendByEmail = () => {
    let { purposes } = payload;
    const alreadySelectSomeSendBy = purposes
      .filter((p) => ['PP-4', 'PP-5'].includes(p.purpose))
      .some((p) => p.consented);
    if (alreadySelectSomeSendBy) {
      return;
    }

    purposes = purposes.map((p) => {
      if (p.purpose !== 'PP-4') return p;

      p.consented = true;
      return p;
    });
    setPayload({ ...payload, purposes });
    setShowSendBy(true);
  };

  const clearAllCheck = () => {
    let { purposes } = payload;
    purposes = purposes.map((p) => {
      p.consented = false;
      return p;
    });
    setPayload({ ...payload, purposes });
    setShowSendBy(false);
  };

  return (
    <>
      <p>
        {/* translate('เปรียบเทียบการทำสัญญา', 'Compare contracts', lang) */}
        <span className="text-p3Medium">
          {translate2(
            'โคเดียมสัญญาว่าจะให้ความคุ้มครองข้อมูลส่วนบุคคลของคุณ',
            'At CODIUM, we guarantee the protection of your personal data',
            lang,
          )}
        </span>{' '}
        {translate2(
          'และเราจะใช้ข้อมูลส่วนบุคคลของคุณเท่าที่จำเป็นสำหรับการนำเสนอผลิตภัณฑ์/บริการที่คุณร้องขอมาเท่านั้น',
          'and ensure it is used only as necessary to deliver the products and services you have requested.',
          lang,
        )}
        <br />
        <br />
        {translate2(
          'ในบางกรณีเราอาจจะติดต่อไปหาคุณเพื่อการนำเสนอผลิตภัณฑ์, บริการหรือกิจกรรมที่ตรงกับความต้องการของคุณ ถ้าหากคุณต้องการให้เราติดต่อไปเพื่อวัตถุประสงค์ดังกล่าว โปรดเลือกให้ความยินยอมกับเราในกรณีต่อไปนี้',
          'In certain cases, we may reach out to share products, services, or activities tailored to your needs. If you would like us to contact you for these purposes, please provide your consent in the scenarios outlined below.',
          lang,
        )}
      </p>
      <br />

      <ul>
        {choices.map((choice) => (
          <li
            key={choice}
            className={
              mode === 'dark'
                ? 'flex items-center mb-4'
                : `px-3 py-4 flex mb-4 rounded-md ${
                    uiCheckbox[choice] ? 'bg-[#D6EBF0]' : 'bg-white'
                  }`
            }
          >
            <div className="w-4 h-4">
              <input
                id={choice}
                type="checkbox"
                checked={uiCheckbox[choice]}
                onChange={() =>
                  handleSelectChoice(consentPurposeDetail[choice].value)
                }
                className="cursor-pointer"
              />
            </div>
            <div className="ml-2">
              <label htmlFor={choice} className="cursor-pointer">
                {translate(consentPurposeDetail[choice], 'label', lang)}
              </label>
            </div>
          </li>
        ))}
      </ul>

      <br />

      {showSendBy ? (
        <>
          <p>
            {translate2(
              'โปรดเลือกช่องทางที่คุณต้องการให้เราติดต่อคุณ',
              'Please select your preferred method of contact.',
              lang,
            )}
          </p>
          <br />

          <ul
            className={
              mode === 'dark'
                ? 'flex space-x-28 items-center mb-6'
                : `flex space-x-28 px-3 py-4 bg-white rounded-md mb-6`
            }
          >
            <li className="flex items-center">
              <input
                id="byEmail"
                type="checkbox"
                checked={uiCheckbox['PP-4']}
                onChange={() => handleSelectSendBy('PP-4')}
                className="cursor-pointer"
              />
              <label htmlFor="byEmail" className="ml-4 cursor-pointer">
                {translate2(consentPurposeDetail['PP-4'].labelTH, consentPurposeDetail['PP-4'].label, lang)}
              </label>
            </li>
            <li className="flex items-center">
              <input
                id="byPhone"
                type="checkbox"
                checked={uiCheckbox['PP-5']}
                onChange={() => handleSelectSendBy('PP-5')}
                className="cursor-pointer"
              />
              <label htmlFor="byPhone" className="ml-4 cursor-pointer">
                {translate2(consentPurposeDetail['PP-5'].labelTH, consentPurposeDetail['PP-5'].label, lang)}
              </label>
            </li>
          </ul>

          {!valid ? (
            <p className="text-red text-p3Regular px-3 -mt-3 mb-6">
              {translate2(
                'กรุณาเลือกช่องทางการติดต่อ',
                'Please select a contact method',
                lang,
              )}
            </p>
          ) : null}
        </>
      ) : null}

      <p className={`${mode === 'dark' ? 'text-white' : 'text-neutral-600'}`}>
        *{' '}
        {translate2(
          'คุณสามารถถอนความยินยอมของคุณได้ทุกเมื่อ สำหรับช่องทางการติดต่อเพื่อขอถอนความยินยอม และรายละเอียดการคุ้มครองข้อมูล ส่วนบุคคลของคุณ โปรดอ่านได้ที่',
          'You may withdraw your consent at any time. For information on how to request the withdrawal of consent and details about how we manage your personal data, please refer to our',
          lang,
        )}{' '}
        <Link href={'/privacy-policy'} passHref>
          <a
            target="_blank"
            className=" text-lightBlue underline cursor-pointer hover:text-lightBlue-600"
          >
            {translate2('ประกาศความเป็นส่วนตัวของเรา', 'Privacy Policy', lang)}
          </a>
        </Link>
      </p>
    </>
  );
};

export default PDPAConsent;

const consentPurposeDetail: {
  [key in ConsentPurposeType]: ConsentPurposeDetail;
} = {
  'PP-1': {
    label:
      'For the purpose of analyzing and presenting products or services that may interest you.',
    labelTH: 'เพื่อการวิเคราะห์และนำเสนอผลิตภัณฑ์บริการที่ฉันอาจสนใจ',
    value: 'PP-1',
  },
  'PP-2': {
    label:
      'To invite you to participate in events or seminars that may be of interest to you.',
    labelTH: 'เพื่อการเชิญฉันเข้าร่วมกิจกรรม/งานสัมมนาที่ฉันอาจสนใจ',
    value: 'PP-2',
  },
  'PP-3': {
    label: 'To share interesting updates and new insights from CODIUM..',
    labelTH: 'เพื่อรับข่าวสารความรู้ใหม่ๆ ที่น่าสนใจจากโคเดียม',
    value: 'PP-3',
  },
  'PP-4': {
    label: 'Email',
    labelTH: 'อีเมล',
    value: 'PP-4',
  },
  'PP-5': {
    label: 'Phone Number',
    labelTH: 'โทรศัพท์',
    value: 'PP-5',
  },
};
