import * as React from 'react';
import Link from 'next/link';
import { strapi } from 'models/conent';
import { useLocale } from '@components/Contexts/LocaleContext';
import { translate2 as translate } from 'utils/translate';

const SuccessRegistration = ({
  showModal,
  setShowModal,
}: {
  [k: string]: any;
}) => {
  const { lang } = useLocale();

  return (
    <>
      {showModal ? (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="bg-[#e6f9fc] border-0 rounded-lg shadow-lg relative flex flex-col h-auto xl:h-1/4 outline-none focus:outline-none">
                <div className="relative p-6 flex flex-col justify-center justify-self-center">
                  <button
                    className="ml-auto border-0 mb-10 text-black float-right text-xl md:text-3xl outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-black -mt-2 h-6 w-6 text-2xl block float-right outline-none focus:outline-none">
                      x
                    </span>
                  </button>
                  <div className="flex justify-center">
                    <img
                      src={`${strapi}/uploads/Group_13916_2x_c5c9fcdc36.png`}
                      className="h-36 w-36 xl:h-32 xl:w-32 rounded-lg"
                    />
                  </div>
                  <p className="mt-8 xl:mt-4 text-center text-neutral text-4xl md:text-2xl xl:text-lg">
                    {translate(
                      'ลงทะเบียนสำเร็จ',
                      'Registration Successful',
                      lang,
                    )}
                  </p>
                  <p className="text-neutral !leading-8 text-center mx-8 md:mx-4 mb-6 md:mb-4  text-3xl md:text-xl xl:text-base">
                    {translate(
                      'ขอบคุณสำหรับข้อมูลค่ะ',
                      'Thank you for contacting us.',
                      lang,
                    )}
                    <br />
                    {translate(
                      'ทางบริษัทจะติดต่อกลับภายใน 1 วันทำการ',
                      'We will contact you as soon as possible within 1 business day.',
                      lang,
                    )}
                  </p>
                  <div className="flex justify-center w-auto mb-8 md:mb-4">
                    <Link href="/blogs">
                      <button
                        className="text-white bg-lightBlue hover:bg-lightBlue-700 font-bold uppercase text-4xl md:text-2xl xl:text-lg outline-none px-6 py-4 xl:py-2 rounded-lg focus:outline-none ease-linear transition-all duration-150 w-auto"
                        type="button"
                      >
                        {translate('อ่านบทความเพิ่มเติม', 'Read more', lang)}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="opacity-25 fixed inset-0 z-40 bg-black"
            onClick={() => setShowModal(false)}
          ></div>
        </div>
      ) : null}
    </>
  );
};

export default SuccessRegistration;
