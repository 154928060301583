import { useLocale } from '@components/Contexts/LocaleContext';
import { PDPAConsent, initialPDPAInfoPayload } from '@components/PDPAConsent';
import { PDPAInfoPayload } from '@components/PDPAConsent/PDPAConsent.model';
import axios from 'axios';
import { be, domain, recaptchaSiteKey } from 'models/conent';
import React from 'react';
import { FC, useEffect, useState } from 'react';
import { GTMPageView } from 'utils/gtm';
import { SendMail, strapiForm } from 'utils/mail';
import { translate, translate2 } from 'utils/translate';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import SuccessRegistration from '@components/eSignature/FormsSection/SuccessRegistration/SuccessRegistration';
import { HoneyPot } from '@components/HoneyPot';
import { captureException } from '@sentry/react';

interface Props {
  product: string;
  event: string;
  scroll?: any;
}

type FormValue = {
  name: string;
  email: string;
  phone: string;
  company_name: string;
  job: string;
  detail: string;
  none: any;
  user_code: string;
};

const fieldMap = {
  name: 'name',
  email: 'email',
  phone: 'phone',
  company_name: 'company_name',
  job: 'job',
  detail: 'detail',
};

const ContactFormNewDesign: FC<Props> = ({ product, event, scroll }) => {
  const [disable, setDisable] = useState(true);
  const { lang } = useLocale();
  let contactRef = React.useRef() as any;
  const [forms, setForms] = React.useState({
    name: '',
    email: '',
    phone: '',
    company_name: '',
    job: '',
    detail: '',
    user_code: '',
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValue>();
  const [pdpaInfo, setPdpaInfo] = useState<PDPAInfoPayload>(null);
  const [pdpaFormValid, setPdpaFormValid] = useState<boolean>(false);

  const [showNoti, setShowNoti] = useState<any>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>();
  const [showSuccess, setShowSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const recaptchaRef = React.useRef() as any;
  const [confirmRecaptcha, setConfirmRecaptcha] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');

  useEffect(() => {
    if (recaptchaToken) {
      setConfirmRecaptcha(true);
    } else {
      setConfirmRecaptcha(false);
    }
  }, [recaptchaToken]);

  useEffect(() => {
    const initPdpaInfo = initialPDPAInfoPayload(null, 'Codium form');
    setPdpaInfo(initPdpaInfo);
  }, []);

  useEffect(() => {
    if (disable) {
      setDisable(false);
    } else {
      window.scrollTo({
        behavior: 'smooth',
        top: contactRef.current.offsetTop - 56,
      });
      setDisable(false);
    }
  }, [scroll]);

  const handleInputForm = (e) => {
    const { name, value } = e.target;
    setForms((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const clearInput = () => {
    (document.getElementsByName('name') as any)[0].value = '';
    (document.getElementsByName('company_name') as any)[0].value = '';
    (document.getElementsByName('job') as any)[0].value = '';
    (document.getElementsByName('email') as any)[0].value = '';
    (document.getElementsByName('phone') as any)[0].value = '';
    (document.getElementsByName('detail') as any)[0].value = '';
    (document.getElementsByName('user_code') as any)[0].value = '';
  };

  const handleRecaptchaChange = () => {
    const token = recaptchaRef.current.getValue();
    setRecaptchaToken(token);
  };

  const handleSubmitForm = (data: FormValue, e: any) => {
    e.preventDefault();

    if (data.user_code) {
      return;
    }

    const token = recaptchaRef.current.getValue();
    if (!token) {
      alert(
        lang === 'en'
          ? 'Please confirm that you are not a robot.'
          : 'กรุณายืนยันว่าคุณไม่ใช่หุ่นยนต์',
      );
      return;
    }

    setIsSubmitting(true);
    submit(data, token);
  };

  const submit = async (data: FormValue, recaptchaToken: string) => {
    const url_ads = window.location.href;
    const ApiUrl = url_ads.includes(domain)
      ? 'https://power-bi.heroapp.dev/api/v1/leads/'
      : 'https://power-bi.heroapp.dev/api/v1/_leads/';
    const from_url = localStorage.getItem('fromUrl') ?? url_ads;

    const dataForm = {
      name: data.name,
      email: data.email,
      phone: data.phone,
      company_name: data.company_name,
      job: data.job,
      detail: data.detail,
      product: product,
      from_url,
      contact_points: ['1'],
    };

    try {
      const res = await axios.post(`${be}/api/codium-forms`, {
        data: {
          ...dataForm,
          codiumForm: forms,
          contact_points: '["1"]',
          recaptcha: recaptchaToken,
        },
        pdpa_info: {
          customer_code: data.email,
          purposes: pdpaInfo.purposes,
        },
      });

      // Google tag manager for submit
      GTMPageView(url_ads, event);
      const attributes = res.data?.data?.attributes;

      // Send email to sale
      if (url_ads.includes(domain) || true) {
        const detail = {
          to: 'sales@codium.co',
          html: `<p>เรียน <a href="mailto:sales@codium.co">sales@codium.co</a></p>
                <p>เรื่อง Lead In Alert</p>
                <p style="padding-left: 40px;"><strong>Product</strong>: ${product}</p>
                <p style="padding-left: 40px;"><strong>Date time</strong>: ${new Date()}</p>
                <p style="padding-left: 40px;"><strong>Name</strong>: ${
                  attributes?.name
                }</p>
                <p style="padding-left: 40px;"><strong>Company</strong>: ${
                  attributes?.company_name ? attributes?.company_name : '-'
                }</p>
                <p style="padding-left: 40px;"><strong>Job Title</strong>: ${
                  attributes?.job ? attributes?.job : '-'
                }</p>
                <p style="padding-left: 40px;"><strong>Phone</strong>: ${
                  attributes?.phone
                }</p>
                <p style="padding-left: 40px;"><strong>Email</strong>: ${
                  attributes?.email
                }</p>
                <p style="padding-left: 40px;"><strong>Detail</strong>: ${
                  attributes?.detail ? attributes?.detail : '-'
                }</p>
                <p><strong>คุณสามารถตรวจสอบรายละเอียดข้อมูลเพิ่มเติมได้ที่</strong>: <a href="${strapiForm}/${
            res.data?.id
          }"> ${be}/api/codium-forms/${res.data?.id}</a></p>
                <p>&nbsp;</p>
                <p>Reported by CODIUM website</p>`,
        };
        SendMail(detail);
      }
      setShowSuccess(true);
    } catch (error) {
      setShowSuccess(false);
      captureException(error);
    } finally {
      setForms({
        name: '',
        email: '',
        phone: '',
        company_name: '',
        job: '',
        detail: '',
        user_code: '',
      });
      clearInput();
      const initPdpaInfo = initialPDPAInfoPayload(null, 'Codium form');
      setPdpaInfo(initPdpaInfo);
      recaptchaRef.current.reset();
      setIsSubmitting(false);
    }

    try {
      await axios.post(ApiUrl, dataForm);
    } catch (error) {
      captureException(error);
    }
  };

  const setAlert = (success: boolean) => {
    if (success) {
      setIsSuccess(true);
    } else {
      setIsSuccess(false);
    }
    setShowNoti(true);
    setTimeout(() => {
      setShowNoti(false);
    }, 2500);
  };

  return (
    <section ref={contactRef} id="forms-contact">
      {showNoti && (
        <div
          className={`top-20 right-3 fixed w-1/5 z-50 border-t-4 rounded-b px-4 py-3 shadow-md ${
            isSuccess
              ? 'bg-green-100 border-green-500 text-green-900'
              : 'bg-red-100 border-red-500 text-red-900'
          }`}
          role="alert"
          data-aos="fade-left"
          data-aos-easing="linear"
          data-aos-duration="300"
        >
          <div className="flex">
            <p className="font-bold">{isSuccess ? 'Success' : 'Error'}</p>
          </div>
        </div>
      )}

      <div>
        <form
          id="forms"
          className="p-6 rounded-lg bg-[#28272C]"
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          {submitForms.map((item, i) => (
            <span key={i}>
              {item.inputType === 'text' && (
                <div className="!mb-[32px]">
                  <label className="text-p3Medium">
                    {translate(item, 'label', lang)}
                    {item.required && <span className="text-red">*</span>}
                  </label>
                  <input
                    className="!mt-[8px] rounded-lg bg-[#40404B] border-none p-2 text-p3Regular placeholder:text-[#6A6A73] w-full !h-[40px]"
                    type="text"
                    name={item.name}
                    {...register(fieldMap[item.name] || 'none', {
                      onChange: (e) => handleInputForm(e),
                      required: {
                        value: item.required,
                        message: `${translate2(
                          'กรุณากรอก',
                          'Please enter your',
                          lang,
                        )} ${translate(item, 'label', lang).toLowerCase()}`,
                      },
                      pattern: {
                        value:
                          item.name == 'email'
                            ? /\S+@\S+\.\S+/
                            : item.name == 'phone'
                            ? /^((\+)33|0)[1-9](\d{2}){4}$/
                            : null,
                        message: translate2(
                          item.name === 'email'
                            ? 'รูปแบบของ Email ไม่ถูกต้อง กรุณากรอก Email ให้ถูกต้อง'
                            : 'รูปแบบของหมายเลขโทรศัพท์ไม่ถูกต้อง กรุณากรอกหมายเลขโทรศัพท์ให้ถูกต้อง',
                          item.name === 'email'
                            ? 'Invalid email format. Please enter a valid email.'
                            : 'Invalid phone number format. Please enter a valid phone number.',
                          lang,
                        ),
                      },
                    })}
                  />
                </div>
              )}

              {item.inputType === 'textarea' && (
                <div className="mb-8">
                  <label className="text-p3Medium">
                    {translate(item, 'label', lang)}
                    {item.required && <span className="text-red">*</span>}
                  </label>
                  <textarea
                    className="mt-2 rounded-lg bg-[#40404B] border-none p-2 text-p3Regular placeholder:text-[#6A6A73] w-full h-36"
                    name={item.name}
                    {...register(fieldMap[item.name] || 'none', {
                      onChange: (e) => handleInputForm(e),
                      required: {
                        value: item.required,
                        message: `${translate2(
                          'กรุณากรอก',
                          'Please enter',
                          lang,
                        )} ${translate(item, 'label', lang).toLowerCase()}`,
                      },
                    })}
                  ></textarea>
                </div>
              )}
              {errors[item.name] && (
                <p className="text-red text-p3Regular px-3 -mt-6 mb-5">
                  {errors[item.name].message}
                </p>
              )}
            </span>
          ))}

          {/* Hidden Honeypot Field */}
          <HoneyPot register={register} onChange={(e) => handleInputForm(e)} />

          <div className="my-8 text-p3Regular">
            <PDPAConsent
              choices={['PP-1', 'PP-2', 'PP-3']}
              payload={pdpaInfo}
              setPayload={setPdpaInfo}
              valid={pdpaFormValid}
              setValid={setPdpaFormValid}
              mode={'dark'}
            />
          </div>

          <div className="w-full text-center">
            <div className="w-fit mx-auto">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={recaptchaSiteKey}
                hl={lang}
                onChange={handleRecaptchaChange}
              />
            </div>
            <button
              id="submitBtn"
              type="submit"
              className="btn text-white bg-lightBlue hover:bg-lightBlue-700 py-2 px-8 rounded-md disabled:opacity-30 mt-4"
              disabled={!pdpaFormValid || !confirmRecaptcha || isSubmitting}
            >
              {isSubmitting ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  {lang === 'en' ? 'Sending...' : 'กำลังส่ง...'}
                </span>
              ) : (
                lang === 'en' ? 'Send' : 'ตกลง'
              )}
            </button>
          </div>
        </form>
        <SuccessRegistration
          showModal={showSuccess}
          setShowModal={setShowSuccess}
        />
      </div>
    </section>
  );
};

export default ContactFormNewDesign;

const submitForms = [
  {
    inputType: 'text',
    label: 'Name',
    labelTH: 'ชื่อ - นามสกุล',
    name: 'name',
    placeHolder: 'Name',
    placeHolderTH: 'ชื่อ - นามสกุล',
    required: true,
  },
  {
    inputType: 'text',
    label: 'Email',
    labelTH: 'อีเมล',
    name: 'email',
    placeHolder: 'Email',
    placeHolderTH: 'อีเมล',
    required: true,
  },
  {
    inputType: 'text',
    label: 'Phone Number',
    labelTH: 'เบอร์โทรศัพท์',
    name: 'phone',
    placeHolder: 'Phone Number',
    placeHolderTH: 'เบอร์โทรศัพท์',
    required: true,
  },
  {
    inputType: 'text',
    label: 'Company Name',
    labelTH: 'บริษัท',
    name: 'company_name',
    placeHolder: 'Company Name',
    placeHolderTH: 'ชื่อบริษัท',
    required: true,
  },
  {
    inputType: 'text',
    label: 'Job Title',
    labelTH: 'ตำแหน่งงาน',
    name: 'job',
    placeHolder: 'Job Title',
    placeHolderTH: 'ตำแหน่งงาน',
    required: true,
  },
  {
    inputType: 'text',
    label: 'What are you interested in?',
    labelTH: 'คุณสนใจในเรื่องอะไร?',
    name: 'detail',
    placeHolder: 'What are you interested in?',
    placeHolderTH: 'คุณสนใจในเรื่องอะไร?',
    required: true,
  },
];
