import { PDPAInfoPayload } from './PDPAConsent.model';

export const initialPDPAInfoPayload = (
  customerCode: string,
  note: string,
): PDPAInfoPayload => {
  return {
    customer_code: customerCode,
    purposes: [
      {
        purpose: 'PP-1',
        consented: true,
        note,
      },
      {
        purpose: 'PP-2',
        consented: false,
        note,
      },
      {
        purpose: 'PP-3',
        consented: false,
        note,
      },
      {
        purpose: 'PP-4',
        consented: true,
        note,
      },
      {
        purpose: 'PP-5',
        consented: false,
        note,
      },
    ],
  };
};
